import request from '@/utils/request';

// ========== 检测规范(标准)相关 ==========

export function productTestRecordDetail(params){
  return request({ url: `/product_test_records/details`, method: 'get',params });
}

export function productTestParamInfoList(params) {
  return request({
    url: '/product_test_param_info/',
    method: 'get',
    params
  })
}

export function productTestParamInfoCreate(data) {
  return request({
    url: '/product_test_param_info/',
    method: 'post',
    data
  })
}

export function productTestParamInfoUpdate(data) {
  // PUT /api/product_test_standards/:id/
  // data中需要有 id, name, test_type, product, ...
  return request({
    url: `/product_test_param_info/${data.id}/`,
    method: 'put',
    data
  })
}

  
export function productTestParamInfoDestroy(data) {
  // DELETE /api/product_test_standards/:id/
  return request({
    url: `/product_test_param_info/${data.id}/`,
    method: 'delete',
    data
  })
}

export function productTestStandardList(params) {
    // GET /api/product_test_standards/
    return request({
      url: '/product_test_standards/',
      method: 'get',
      params
    })
  }
  
  export function productTestStandardCreate(data) {
    // POST /api/product_test_standards/
    return request({
      url: '/product_test_standards/',
      method: 'post',
      data
    })
  }
  
  export function productTestStandardUpdate(data) {
    // PUT /api/product_test_standards/:id/
    // data中需要有 id, name, test_type, product, ...
    return request({
      url: `/product_test_standards/${data.id}/`,
      method: 'put',
      data
    })
  }
  
  export function productTestStandardDestroy(data) {
    // DELETE /api/product_test_standards/:id/
    return request({
      url: `/product_test_standards/${data.id}/`,
      method: 'delete',
      data
    })
  }
  
  // ========== 检测规范(标准)相关_物料 ==========

  export function materialTestParamInfoList(params) {
    return request({
      url: '/material_test_param_info/',
      method: 'get',
      params
    })
  }
  
  export function materialTestParamInfoCreate(data) {
    return request({
      url: '/material_test_param_info/',
      method: 'post',
      data
    })
  }
  
  export function materialTestParamInfoUpdate(data) {
    // PUT /api/product_test_standards/:id/
    // data中需要有 id, name, test_type, product, ...
    return request({
      url: `/material_test_param_info/${data.id}/`,
      method: 'put',
      data
    })
  }
  
    
  export function materialTestParamInfoDestroy(data) {
    // DELETE /api/product_test_standards/:id/
    return request({
      url: `/material_test_param_info/${data.id}/`,
      method: 'delete',
      data
    })
  }

export function materialTestStandardList(params) {
  // GET /api/material_test_standards/
  return request({
    url: '/material_test_standards/',
    method: 'get',
    params
  })
}

export function materialTestStandardCreate(data) {
  // POST /api/material_test_standards/
  return request({
    url: '/material_test_standards/',
    method: 'post',
    data
  })
}

export function materialTestStandardUpdate(data) {
  // PUT /api/material_test_standards/:id/
  // data中需要有 id, name, test_type, material, ...
  return request({
    url: `/material_test_standards/${data.id}/`,
    method: 'put',
    data
  })
}

export function materialTestStandardDestroy(data) {
  // DELETE /api/material_test_standards/:id/
  return request({
    url: `/material_test_standards/${data.id}/`,
    method: 'delete',
    data
  })
}


  // ========== 参数管理相关 ==========
  
  // 获取指定规范下的所有参数
  export function productTestParamList(params) {
    // GET /api/product_test_params/?standard=xxx
    return request({
      url: '/product_test_params/',
      method: 'get',
      params,
    })
  }
  
  // 新建单个参数
  export function productTestParamCreate(data) {
    // POST /api/product_test_params/
    return request({
      url: '/product_test_params/',
      method: 'post',
      data
    })
  }
  
  // 更新单个参数
  export function productTestParamUpdate(data) {
    // PUT /api/product_test_params/:id/
    return request({
      url: `/product_test_params/${data.id}/`,
      method: 'put',
      data
    })
  }
  
  // 删除单个参数
  export function productTestParamDestroy(data) {
    // DELETE /api/product_test_params/:id/
    return request({
      url: `/product_test_params/${data.id}/`,
      method: 'delete',
      data
    })
  }
  
  // ========= 批量提交示例接口(可选) ==========
  // 如果你想一次性提交多个新增/修改/删除，也可以设计一个批量接口：
  export function productTestParamSync(payload) {
    //
    return request({
      url: `/product_test_params/sync_params/`,
      method: 'post',
      data: payload
    })
  }



   // ========== 参数管理相关(物料) ==========
  
  // 获取指定规范下的所有参数
  export function materialTestParamList(params) {
    // GET /api/material_test_params/?standard=xxx
    return request({
      url: '/material_test_params/',
      method: 'get',
      params
    })
  }
  
  // 新建单个参数
  export function materialTestParamCreate(data) {
    // POST /api/material_test_params/
    return request({
      url: '/material_test_params/',
      method: 'post',
      data
    })
  }
  
  // 更新单个参数
  export function materialTestParamUpdate(data) {
    // PUT /api/material_test_params/:id/
    return request({
      url: `/material_test_params/${data.id}/`,
      method: 'put',
      data
    })
  }
  
  // 删除单个参数
  export function materialTestParamDestroy(data) {
    // DELETE /api/product_test_params/:id/
    return request({
      url: `/material_test_params/${data.id}/`,
      method: 'delete',
      data
    })
  }
  
  // ========= 批量提交示例接口(可选) ==========
  // 如果你想一次性提交多个新增/修改/删除，也可以设计一个批量接口：
  export function materialTestParamSync(payload) {
    //
    return request({
      url: `/material_test_params/sync_params/`,
      method: 'post',
      data: payload
    })
  }

/** ********************** 产品测试记录 ****************************/
// 获取产品测试记录列表
export function productTestRecordList(params) {
  return request({ url: '/product_test_records/', method: 'get', params });
}

// 创建产品测试记录
export function productTestRecordCreate(data) {
  return request({ url: '/product_test_records/', method: 'post', data });
}

// 更新产品测试记录
export function productTestRecordUpdate(data) {
  return request({ url: `/product_test_records/${data.id}/`, method: 'put', data });
}

// 删除产品测试记录
export function productTestRecordDestroy(data) {
  return request({ url: `/product_test_records/${data.id}/`, method: 'delete', data });
}


export function productTestResultsBeach(data) {
  return request({ url: '/product_test_results/beach/', method: 'post', data });
}

/** ********************** 物料测试记录 ****************************/
export function materialTestRecordDetail(params){
  return request({ url: `/material_test_records/details`, method: 'get',params });
}

// 获取物料测试记录列表
export function materialTestRecordList(params) {
  return request({ url: '/material_test_records/', method: 'get', params });
}

// 创建物料测试记录
export function materialTestRecordCreate(data) {
  return request({ url: '/material_test_records/', method: 'post', data });
}

// 更新物料测试记录
export function materialTestRecordUpdate(data) {
  return request({ url: `/material_test_records/${data.id}/`, method: 'put', data });
}

// 删除物料测试记录
export function materialTestRecordDestroy(data) {
  return request({ url: `/material_test_records/${data.id}/`, method: 'delete', data });
}

/** ********************** 物料测试结果 ****************************/
// 获取物料测试记录结果



export function materialTestResultsList(params) {
  return request({ url: '/material_test_results/', method: 'get', params });
}

// 创建物料测试结果
export function materialTestResultsCreate(data) {
  return request({ url: '/material_test_results/', method: 'post', data });
}


export function materialTestResultsBeach(data) {
  return request({ url: '/material_test_results/beach/', method: 'post', data });
}

// export function materialTestResultsBUpdate(data) {
//   return request({ url: `/material_test_results/beach_update/`, method: 'put', data });
// }

// 更新物料测试结果
export function materialTestResultsUpdate(data) {
  return request({ url: `/material_test_results/${data.id}/`, method: 'put', data });
}

// 删除物料测试结果(一组同时删除)
export function materialTestResultsBDestroy(data) {
  return request({ url: `/material_test_results/beach_delete`, method: 'delete', data });
}

export function productTestResultsBDestroy(data) {
  return request({ url: `/product_test_results/beach_delete`, method: 'delete', data });
}

// 删除物料测试结果
export function materialTestResultsDestroy(data) {
  return request({ url: `/material_test_results/`, method: 'delete', data });
}


/** ********************** 待检采购物料 ****************************/
// 获取待检采购物料列表
export function pendingPurchaseMaterialsList(params) {
  return request({ url: '/purchase_materials/pending/', method: 'get', params });
}